@import '../../styles/global.scss';

.cat-registration {
    margin: 0 0 10px 0;
    padding: 20px;
    border: 3px solid $main-color-back2;

    h2 {
        margin: 0 0 0 0;
        padding: 4px 0 10px 0;
    }

    ul {
        padding: 20px;
        margin: 0;
        line-height: 2rem;
    }
}