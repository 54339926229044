@import '../../styles/global.scss';

/////// METRIC ////////

.edit-create__wrap {
    height: auto;
    display: flex;
    justify-content: center;
    background: linear-gradient(rgb(255, 255, 255, 0.6), rgb(118, 124, 118, 0.3)),
        url("../../img/pink-background.jpeg");
    background-size: cover;


    @include respond-to("medium") {
        display: flex;
        justify-content: center;
        // height: 100vh;
        // border: 1px solid red;
        background: linear-gradient(rgb(255, 255, 255, 0.6), rgb(118, 124, 118, 0.3)),
            url("../../img/pink-background.jpeg");
        background-size: cover;
    }

    .error {
        color: red;
        margin: 2px;
    }

    // .metric-img {
    //     display: flex;
    //     justify-content: center;
    //     padding-top: 2rem;
    //     width: 100%;

    //     img {
    //         width: 100%;
    //     }
    // }

    .edit-create__img {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-top: 2rem;
        width: 100%;
        margin: 0; // This removes the default margin of the figure element

        figcaption {
            margin-bottom: 10px; // You can adjust this based on your design
            text-align: center;
            font-weight: bold;
          }
    
          img {
            max-width: 100%; // Adjusts image to fit the container
            height: auto; // Ensures the image scales properly
            border: 1px solid #ddd; // Optional: add border to image
          }
    }

    .edit-create__form {
        background-color: white;
        width: 360px;
        border-radius: 10px;
        padding: 10px;
        // font-size: small;
        margin: 20px;
        padding: 20px 30px;

        @include respond-to("medium") {
            background-color: white;
            width: 520px;
            border-radius: 10px;
            margin: 60px;
            padding: 60px;
        }

    }
}