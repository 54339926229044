@import '../../../styles/global.scss';

.pedigree-tab {
    // color: gray;
    // overflow-x: auto;
    // white-space: nowrap;
    overflow: hidden;

    .grid-container-mother {
        padding: 1rem 0.5rem;
        overflow-x: auto;
        white-space: nowrap;
        display: grid;
        // grid-template-columns: repeat(4, 1fr); //Adjust the number of columns as needed
        grid-template-areas:
            'grid-header1 grid-header2 grid-header3 grid-header4'
            'grid-mother grid-mother-grandmother grid-mother-grandmother-great-grandmother grid-mother-grandmother-great-grandmother-mother'
            'grid-mother grid-mother-grandmother grid-mother-grandmother-great-grandmother grid-mother-grandmother-great-grandmother-father'
            'grid-mother grid-mother-grandmother grid-mother-grandmother-great-grandfather grid-mother-grandmother-great-grandfather-mother'
            'grid-mother grid-mother-grandmother grid-mother-grandmother-great-grandfather grid-mother-grandmother-great-grandfather-father'
            'grid-mother grid-mother-grandfather grid-mother-grandfather-great-grandmother grid-mother-grandfather-great-grandmother-mother'
            'grid-mother grid-mother-grandfather grid-mother-grandfather-great-grandmother grid-mother-grandfather-great-grandmother-father'
            'grid-mother grid-mother-grandfather grid-mother-grandfather-great-grandfather grid-mother-grandfather-great-grandfather-mother'
            'grid-mother grid-mother-grandfather grid-mother-grandfather-great-grandfather grid-mother-grandfather-great-grandfather-father'
        ;
        grid-template-columns: 0.5fr 0.8fr 1fr 1fr;
        gap: 10px;
        align-items: center;
    }

    .pedigree-tab__ems {
        background-color: $main-color-back;
        padding: 0 5px;
    }

    .pedigree-tab__birthdate {
        font-style: italic;
    }

    .grid-item {
        border: 1px solid white;
        text-align: center;
        padding: 12px;
        align-items: center;
    }

    .grid-container-father {
        padding: 1rem 0.5rem;
        overflow-x: scroll;
        white-space: nowrap;
        display: grid;
        grid-template-areas:
            'grid-father grid-father-grandmother grid-father-grandmother-great-grandmother grid-father-grandmother-great-grandmother-mother'
            'grid-father grid-father-grandmother grid-father-grandmother-great-grandmother grid-father-grandmother-great-grandmother-father'
            'grid-father grid-father-grandmother grid-father-grandmother-great-grandfather grid-father-grandmother-great-grandfather-mother'
            'grid-father grid-father-grandmother grid-father-grandmother-great-grandfather grid-father-grandmother-great-grandfather-father'
            'grid-father grid-father-grandfather grid-father-grandfather-great-grandmother grid-father-grandfather-great-grandmother-mother'
            'grid-father grid-father-grandfather grid-father-grandfather-great-grandmother grid-father-grandfather-great-grandmother-father'
            'grid-father grid-father-grandfather grid-father-grandfather-great-grandfather grid-father-grandfather-great-grandfather-mother'
            'grid-father grid-father-grandfather grid-father-grandfather-great-grandfather grid-father-grandfather-great-grandfather-father'
        ;
        grid-template-columns: 0.5fr 0.8fr 1fr 1fr;
        gap: 10px;
        align-items: center;
        border-top: 6px double gray;
    }

    .grid-header1 {
        margin: auto;
        font-weight: 500;
        grid-area: grid-header1;
        padding-bottom: 1rem;
        color: gray;
    }

    .grid-header2 {
        margin: auto;
        font-weight: 500;
        grid-area: grid-header2;
        padding-bottom: 1rem;
        color: gray;
    }

    .grid-header3 {
        margin: auto;
        font-weight: 500;
        grid-area: grid-header3;
        padding-bottom: 1rem;
        color: gray;
    }

    .grid-header4 {
        margin: auto;
        font-weight: 500;
        grid-area: grid-header4;
        padding-bottom: 1rem;
        color: gray;
    }

    .grid-mother {
        border: 1px solid white;
        align-items: center;
        grid-area: grid-mother;
        margin-right: -90px;
    }

    .grid-parent-toptext {
        padding-bottom: 7px;
        color: gray;
        font-size: small;
        font-style: italic;
        display: flex;
        justify-content: space-between;
    }

    .flex {
        display: flex;
        justify-content: center;
        gap: 0.8rem;
        padding-top: 5px;
    }

    .grid-mother-grandmother {
        // color: purple;
        border: 1px solid white;
        padding: 20px;
        // display: flex;
        align-items: center;
        grid-area: grid-mother-grandmother;
        // margin-right: -20px;
    }

    .grid-mother-grandfather {
        // color: blue;
        border: 1px solid white;
        padding: 20px;
        // display: flex;
        align-items: center;
        grid-area: grid-mother-grandfather;
        // margin-right: -20px;
    }

    .grid-mother-grandmother-great-grandmother {
        // color: purple;
        border: 1px solid white;
        padding: 10px;
        // display: flex;
        // font-size: small;
        // align-items: center;
        grid-area: grid-mother-grandmother-great-grandmother;
    }

    .grid-mother-grandmother-great-grandfather {
        // color: blue;
        border: 1px solid white;
        padding: 10px;
        // display: flex;
        align-items: center;
        grid-area: grid-mother-grandmother-great-grandfather;
    }

    .grid-mother-grandfather-great-grandmother {
        border: 1px solid white;
        padding: 10px;
        // display: flex;
        align-items: center;
        grid-area: grid-mother-grandfather-great-grandmother;
    }

    .grid-mother-grandfather-great-grandfather {
        border: 1px solid white;
        padding: 10px;
        // display: flex;
        align-items: center;
        grid-area: grid-mother-grandfather-great-grandfather;
    }

    .grid-mother-grandmother-great-grandmother-mother {
        border: 1px solid white;
        padding: 10px;
        // display: flex;
        align-items: center;
        grid-area: grid-mother-grandmother-great-grandmother-mother;
    }

    .grid-mother-grandmother-great-grandmother-father {
        border: 1px solid white;
        padding: 10px;
        // display: flex;
        align-items: center;
        grid-area: grid-mother-grandmother-great-grandmother-father;
    }

    .grid-mother-grandmother-great-grandfather-mother {
        border: 1px solid white;
        padding: 10px;
        // display: flex;
        align-items: center;
        grid-area: grid-mother-grandmother-great-grandfather-mother;
    }

    .grid-mother-grandmother-great-grandfather-father {
        border: 1px solid white;
        padding: 10px;
        // display: flex;
        align-items: center;
        grid-area: grid-mother-grandmother-great-grandfather-father;
    }

    //  ======
    .grid-mother-grandfather-great-grandmother-mother {
        border: 1px solid white;
        padding: 10px;
        // display: flex;
        align-items: center;
        grid-area: grid-mother-grandfather-great-grandmother-mother;
    }

    .grid-mother-grandfather-great-grandmother-father {
        border: 1px solid white;
        padding: 10px;
        // display: flex;
        align-items: center;
        grid-area: grid-mother-grandfather-great-grandmother-father;
    }

    .grid-mother-grandfather-great-grandfather-mother {
        border: 1px solid white;
        padding: 10px;
        // display: flex;
        align-items: center;
        grid-area: grid-mother-grandfather-great-grandfather-mother;
    }

    .grid-mother-grandfather-great-grandfather-father {
        border: 1px solid white;
        padding: 10px;
        // display: flex;
        align-items: center;
        grid-area: grid-mother-grandfather-great-grandfather-father;
    }

    //////// Father //////////
    .grid-father {
        // color: red;
        border: 1px solid white;
        padding: 20px;
        // display: flex;
        align-items: center;
        grid-area: grid-father;
        margin-right: -90px;
    }

    .grid-father-grandmother {
        border: 1px solid white;
        padding: 20px;
        align-items: center;
        grid-area: grid-father-grandmother;
        // margin-right: -20px;
    }

    .grid-father-grandfather {
        border: 1px solid white;
        padding: 20px;
        align-items: center;
        grid-area: grid-father-grandfather;
        // margin-right: -20px;
    }

    .grid-father-grandmother-great-grandmother {
        border: 1px solid white;
        padding: 10px;
        align-items: center;
        grid-area: grid-father-grandmother-great-grandmother;
    }

    .grid-father-grandmother-great-grandfather {
        border: 1px solid white;
        padding: 10px;
        align-items: center;
        grid-area: grid-father-grandmother-great-grandfather;
    }

    .grid-father-grandfather-great-grandmother {
        border: 1px solid white;
        padding: 10px;
        align-items: center;
        grid-area: grid-father-grandfather-great-grandmother;
    }

    .grid-father-grandfather-great-grandfather {
        border: 1px solid white;
        padding: 20px;
        align-items: center;
        grid-area: grid-father-grandfather-great-grandfather;
    }

    .grid-father-grandmother-great-grandmother-mother {
        border: 1px solid white;
        padding: 10px;
        align-items: center;
        grid-area: grid-father-grandmother-great-grandmother-mother;
    }

    .grid-father-grandmother-great-grandmother-father {
        border: 1px solid white;
        padding: 10px;
        align-items: center;
        grid-area: grid-father-grandmother-great-grandmother-father;
    }

    .grid-father-grandmother-great-grandfather-mother {
        border: 1px solid white;
        padding: 10px;
        align-items: center;
        grid-area: grid-father-grandmother-great-grandfather-mother;
    }

    .grid-father-grandmother-great-grandfather-father {
        border: 1px solid white;
        padding: 10px;
        align-items: center;
        grid-area: grid-father-grandmother-great-grandfather-father;
    }

    //  ======
    .grid-father-grandfather-great-grandmother-mother {
        border: 1px solid white;
        padding: 10px;
        align-items: center;
        grid-area: grid-father-grandfather-great-grandmother-mother;
    }

    .grid-father-grandfather-great-grandmother-father {
        border: 1px solid white;
        padding: 10px;
        align-items: center;
        grid-area: grid-father-grandfather-great-grandmother-father;
    }

    .grid-father-grandfather-great-grandfather-mother {
        border: 1px solid white;
        padding: 10px;
        align-items: center;
        grid-area: grid-father-grandfather-great-grandfather-mother;
    }

    .grid-father-grandfather-great-grandfather-father {
        border: 1px solid white;
        padding: 10px;
        align-items: center;
        grid-area: grid-father-grandfather-great-grandfather-father;
    }


}

.male:hover {
    background-color: $male-blue-dark;
}

.male {
    background-color: $male-blue;

}

.female {
    background-color: $female-pink;
}

.female:hover {
    background-color: $female-pink-dark;
}