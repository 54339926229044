@import '../../styles/global.scss';


.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f7f7f7;

    h1 {
        font-size: 6em;
        color: $main-color;
        margin: 0;
    }

    p {
        font-size: 1.5em;
        color: #666;
        margin: 10px 0;
    }

    a {
        color: $main-color;
        text-decoration: none;
        padding: 10px 20px;
        border: 2px solid $main-color;
        border-radius: 5px;
        transition: background-color 0.3s, color 0.3s;
    }

    a:hover {
        background-color: $main-color;
        color: white;
    }

}