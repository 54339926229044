@import '../../styles/variables';

.cat-profile-sale__container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    // overflow: hidden;
    // height: 100%;
    // cursor: default;
    border: 1px solid lightgray;
    // transition: box-shadow 0.15s ease-in-out;

    @include respond-to("medium") {
        display: flex;
        flex-direction: row;
        gap: 0.9rem;
    }

    @include respond-to("large") {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }

    // &:hover {
    //     cursor: pointer;
    //     box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
    // }
    .cat-profile-sale__left {
        .catAvaRelative {
            position: relative;
        }

        .catCardImg {
            width: 100%;
            object-fit: cover;

            // height: auto;
            @include respond-to('medium') {
                width: auto;
            }
        }

        .iconMarsWrap {
            border: 1px solid rgba(0, 0, 0, 0);
            background-color: rgba(0, 0, 0, 0.2);
            padding: 5px;
            position: absolute;
            top: 0;
            right: 0px;
        }

        .iconHeartWrap {
            padding: 5px;
            position: absolute;
            bottom: 3px;
            right: 0px;
        }

        .idWrap {
            background-color: rgba(0, 0, 0, 0.5);
            padding: 4px 1px 0 7px;
            position: absolute;
            top: 0;
            left: 0;
        }

        .iconMars,
        .iconVenus {
            font-size: 1.1em;
            width: 24px;
            height: 24px;
        }

        .iconMars {
            color: var(--icon-mars);
        }

        .iconVenus {
            color: var(--icon-venus);
        }

        .id {
            color: rgba(255, 255, 255, 1);
            font-size: 0.8rem;
        }

        .iconHeart {
            font-size: 1.1em;
            width: 24px;
            height: 24px;
            color: rgba(255, 255, 255, 0.7);
            transition: color 0.15s ease-in-out;

            &:hover {
                cursor: pointer;
                color: rgba(245, 138, 222, 0.8);
            }

            &.active {
                color: rgb(255, 138, 225);
            }
        }

    }

    .cat-profile-sale__desc {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        // text-align: center;
        // padding: 0.2em 0.1em;
        height: 100%;

        @include respond-to("medium") {
            // display: flex;
            // flex-direction: row;
            // justify-content: space-between;
            // text-align: center;
            padding: 0.8em 0.1em;
            height: 100%;
            width: 100%;
        }

        .cat-profile-sale__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 3px 6px 3px 3px;
        }

        .cat-profile-sale__header_sale {
            font-size: small;
        }

        .cat-profile-sale__header_breed {
            font-size: small;
            // margin: 4px 0 4px 0;
            padding: 3px 6px 3px 3px;
        }

        .descBottomTextSmall {
            font-size: small;
            margin: 0.3em 0;
            padding: 0.1em 0;
        }

        .descPriceWrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            background-color: $main-color-back;
            padding: 0.2em;

            @include respond-to('medium') {
                margin: 0.1rem 0 0.1em 0;
                padding: 0.4em;
            }
        }

        .descPrice {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            font-weight: 800;
            font-size: 1.3em;
            padding: 0.2em 0.4em;
            color: $main-color-dark;
        }

        .descPriceText {
            font-size: 0.6em;
            margin: 0 1px 0 5px;

            @include respond-to ('medium') {
                font-size: default;
                margin: 0 1px 0 5px;

            }
        }



        .cat-profile-sale__table {
            width: 100%;
            border-collapse: collapse;
            font-size: small;
            // margin-bottom: 20px;

            td {
                padding: 4px;
                // border-bottom: 1px solid #ddd;

                &:first-child {
                    // font-weight: bold;
                    color: #666;
                    width: 120px;
                    text-align: right;
                    padding: 5px 10px 5px 0;
                }

                &:last-child {
                    // color: #333;
                    font-weight: 500;
                }
            }

            tr:nth-child(odd) {
                background-color: #f2f2f2;
                /* Light gray */
            }

            tr:nth-child(even) {
                background-color: #ffffff;
                /* White */
            }
        }

        .birthdate_td {
            display: flex;
            flex-direction: column;
        }

        .age {
            font-size: 0.9em;
            font-style: italic;
        }

        .icon {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
            // justify-content: center;
        }

        .iconMarsAndVenus {
            margin-right: 7px;
        }
    }

    .cat-profile-sale__info {
        display: flex;
        justify-content: space-between;
        font-size: x-small;
        color: #666;
        margin: 2px 4px;
    }
}

.cat-profile__clue {
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: all .09s ease-out;
    user-select: none;

    &::before {
        content: attr(data-clue);
        position: absolute;
        right: 5px;
        top: -28px;
        /* Below the element */
        transform: translateY(-50%) translateX(-10px);
        padding: 10px 10px;
        color: whitesmoke;
        background-color: rgba($main-color, 0.9);
        border-radius: 3px;
        white-space: nowrap;
        visibility: hidden; // no in document
        opacity: 0;
        transition: opacity 0.3s ease-out, transform 0.3s ease-out;
        font-weight: normal;
        font-size: small;
        z-index: -1;

        @include respond-to("medium") {
            content: attr(data-clue);
            position: absolute;
            right: 100%;
            /* Position on the left */
            top: 50%;
            left: auto;
            transform: translateY(-50%) translateX(-10px);
            padding: 10px 10px;
            color: whitesmoke;
            background-color: rgba($main-color, 0.9);
            border-radius: 3px;
            white-space: nowrap;
            visibility: hidden; // no in document
            opacity: 0;
            transition: opacity 0.3s ease-out, transform 0.3s ease-out;
            font-weight: normal;
            font-size: small;
            z-index: -1;
        }
    }

    &:hover {
        background-color: $main-color-back;

        &::before {
            visibility: visible;
            opacity: 1;
            z-index: 10;
            /* On mobile (default) */
            transform: translateY(-50%) translateX(-20px);
            /* Moves it further left smoothly */
            /* Moves further down smoothly */

            /* On medium and larger screens */
            @include respond-to("medium") {
                transform: translateY(-50%) translateX(-20px);
                /* Moves further left smoothly */
            }
        }

    }

}