@import '../../styles/global';

/* Add this to your CSS file */
.order__container {
  display: flex;
  gap: 1rem;
  padding: 5px;
  flex-wrap: wrap;

  @include respond-to("medium") {
    display: flex;
    gap: 2rem;
    padding: 10px;
    flex-wrap: nowrap;
  }

  .form__container {
    width: 100%;
    padding: 10px;
  }

  .form__container form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  // .order__form {}

  .form__container label {
    margin-bottom: 5px;
  }

  .form__container input {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
  }

  .form__container button {
    padding: 5px;
    height: 50px;
  }

  .order__p {
    font-weight: 600;
    // font-family:'Lucida Sans';

  }

  .order__button {
    display: flex;
    justify-content: space-around;
    align-items: center;

    width: 100%;
    height: auto;
    padding: $button-padding;
    border: 2px solid $main-color;
    background-color: $main-color-back;
    color: $main-color;
    border-radius: 8px;
    // font-weight: 600;
    font-size: 1.05rem;
    cursor: pointer;
    margin: 1rem 0 1rem 0;
    transition: all 0.3s ease;
    // text-transform: uppercase;
    // z-index: 10;

    &:hover {
      border: 2px solid $main-color-dark;
      background-color: $main-color-back2;
      color: $main-color-dark;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      // transform: translateY(-2px);
    }
  }

  .order__button_text {
    // font-size: small;
  }

  .order__button_price {
    color: $main-color-dark;
    font-size: large;
    font-weight: 600;
  }

  .icon__mars-and-venus {
    margin: 0 6px 0 0
  }

  .textarea {
    padding: 10px;
  }
}