@import '../../styles/variables';


.main {
    // padding: 5px;

    .main-block {
        // max-height: 320px;
        // padding: 10px 0;
        // margin: 10px 0 0 0;
        // margin: 0 auto;
        // text-align: center;

        @include respond-to("medium") {
            // max-height: 380px;
            min-height: 240px;
            // height: 100%;
            padding: 10px 0;
        }

        h2 {
            padding-bottom: 0.8rem;
        }

        .strong {
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 3rem;
        }

        .main-text__header {}

        .img-container {
            width: 100%;
            position: relative;
            // display: inline-block;
            // border: 1px solid aqua;

            @include respond-to("medium") {
                width: 100%;
                position: relative;
            }

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                // display: block;

                @include respond-to("medium") {
                    width: 100%;
                    // height: 100%;
                    object-fit: cover;
                    // display: block;
                    // display: flex;
                    padding-top: 20px;

                }
            }

            .text-overlay {
                position: static;
                // bottom: 80px;
                // display: flex;
                // justify-content: flex-start;
                // align-items: top;
                // top: 0;

                @include respond-to("414") {
                    bottom: 90px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: top;
                    top: 0;
                }

                @include respond-to("medium") {
                    position: absolute;
                    display: flex;
                    justify-content: flex-start;
                    align-items: top;
                    top: 0;
                    width: 100%;
                    padding: 0 0 0 10px;
                }

                @include respond-to("large") {
                    position: absolute;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    align-items: top;
                    top: 0px;
                    width: 100%;
                }

                h2 {
                    margin: 0;
                    padding: 5px;

                    @include respond-to("medium") {
                        background-color: $main-color-back;
                        // color: white;
                    }
                }

                p {
                    margin: 0;
                    padding: 0;
                    font-size: small;
                    padding: 5px;

                    @include respond-to("medium") {
                        font-size: inherit;
                        margin: 0;
                        background-color: $main-color-back;
                        padding: 5px;

                    }
                }
            }
        }
    }

    .main-breeds__wrap {
        margin: 0 auto;
        text-align: center;
        padding: 1rem 0 1rem 0;
        // border: 2px solid green;

        h2 {
            text-align: center;
            background-color: $main-color-back;
            padding: 1rem 0 1rem 0;
            // display: block;
            margin: 0 0 1rem;
            // border: 2px solid red;
        }

        .main-breeds__breeds {
            display: grid;
            gap: 1.5rem;
            grid-template-columns: 1fr 1fr 1fr;
            padding: 1em 0 1.4em 0;
            align-items: end;

            // justify-content: space-evenly;
            // flex-wrap: wrap;
            // flex-direction: row;
            // border: 2px solid red;
            @include respond-to("medium") {
                // padding: 10px;
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            @include respond-to("large") {
                // padding: 10px;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            }

            @include respond-to("xl") {
                // padding: 10px;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            }
        }

        .main-breeds__img-container {
            // max-height: 120px;
            // overflow: 'hidden';


            .main-breeds__img {
                width: 100%;
                height: auto;
                object-fit: cover;

                @include respond-to("medium") {
                    // max-height: 200px;
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                }
            }

            .image-caption {
                text-align: center;
                font-size: small;

                @include respond-to("s") {
                    text-align: center;
                    font-size: initial;
                }
            }

        }
    }

    .main-sell__wrap {
        background-color: $main-color-back;
        width: 100%;
        // margin: 0 auto;
        text-align: center;
        padding: 1em 0 2em 0;
        // border: 2px solid green;

        h2 {
            text-align: center;
            // background-color: $main-color-back;
            padding: 1rem 0 1rem 0;
            margin: 0;
        }
    }

}