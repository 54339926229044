@import './_variables';

//For information
// $main-color-back : rgb(195, 132, 144, 0.2);
// $main-color-dark: rgb(145, 97, 106);
// $white-transparent: rgba(255, 255, 255, 0.5);

// $female-pink: rgba(250, 236, 249, 255);
// $male-blue: rgba(233, 240, 249, 255);

// $male-blue-dark: rgba(223, 230, 239, 255);
// $female-pink-dark: rgba(240, 226, 239, 255);

// $button-color: rgb(139, 139, 234);

// $button-light: rgb(204, 204, 243);
// $button-light-hover: rgb(204, 204, 243, 0.5);
// $button-light2: rgb(170, 160, 255,0.5);
// $button-light2-hover: rgb(170, 160, 255);
// $white-transparent-darker: rgba(255, 255, 255, 0.8);

// // Button variables
// $button-bg-color: #007bff;
// $button-text-color: #fff;
// $button-border-radius: 8px;
// $button-padding: 10px 20px;

.joinButton {
    width: 100%;
    height: 50px;
    padding: $button-padding;
    border: 2px solid $main-color-dark;
    background-color: $main-color;
    color: white;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.05rem;
    cursor: pointer;
    margin: 1rem 0 1rem 0;
    transition: all 0.3s ease;
    text-transform: uppercase;

    &:hover {
        border: 2px solid $main-color;
        background-color: $main-color-dark;
    }
}

.primary-button-small {
    width: 50%;
    height: 50px;
    padding: $button-padding;
    border: 2px solid $main-color-dark;
    background-color: $main-color;
    color: white;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.05rem;
    cursor: pointer;
    margin: 1rem 0 1rem 0;
    transition: all 0.3s ease;
    text-transform: uppercase;

    &:hover {
        border: 2px solid $main-color;
        background-color: $main-color-dark;
    }
}

.secondary-button {
    width: 100%;
    margin: 0 auto;
    height: 38px;
    padding: $button-padding;
    border: 2px solid $main-color;
    background-color: $main-color-back;
    color: $main-color;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.05rem;
    cursor: pointer;
    margin: 1rem 0 1rem 0;
    transition: all 0.3s ease;
    text-transform: uppercase;
    // text-align: center;
    // z-index: 10;

    &:hover {
        border: 2px solid $main-color-dark;
        background-color: $main-color-back2;
        color: $main-color-dark;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        // transform: translateY(-2px);
    }
}

.secondary-button-small {
    width: 90%;
    height: 30px;
    padding: $button-padding;
    border: 2px solid $main-color;
    background-color: $main-color-back;
    color: $main-color;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.05rem;
    cursor: pointer;
    margin: 1rem 0 1rem 0;
    transition: all 0.3s ease;
    text-transform: uppercase;
    // text-align: center;
    // z-index: 10;

    &:hover {
        border: 2px solid $main-color-dark;
        background-color: $main-color-back2;
        color: $main-color-dark;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        // transform: translateY(-2px);
    }
}

.button1 {
    // background-color: $main-color;
    // color: $button-text-color;
    // border-radius: $button-border-radius;
    // padding: $button-padding;
    // border: 2px solid $main-color-dark;
    // font-weight: 600;
    // font-size: large;
    // cursor: pointer;
    // transition: all 0.3s ease;
    // width: 100%;
    margin-top: 60px;

    &:hover {
        background-color: $main-color-dark;
        // background-color: darken($main-color-dark, 10%);
        border: 2px solid $main-color;

    }
}

.button2 {
    width: auto;
    height: 40px;
    padding: $button-padding;
    border: 2px solid $main-color;
    background-color: $main-color-back;
    color: $main-color;
    border-radius: 8px;
    font-weight: 600;
    // font-size: 1rem;
    cursor: pointer;
    // margin: 2rem 0 2rem 0;
    transition: all 0.3s ease;
    text-transform: uppercase;

    &:hover {
        border: 2px solid $main-color-dark;
        background-color: $main-color-back2;
        color: $main-color-dark;
    }

    @include respond-to("medium") {
        width: 50%;
        height: 50px;
        padding: $button-padding;
        border: 2px solid $main-color;
        background-color: $main-color-back;
        color: $main-color;
        border-radius: 8px;
        font-weight: 600;
        font-size: 1.05rem;
        cursor: pointer;
        margin: 2.2rem 0 2.2rem 0;
        transition: all 0.3s ease;
        text-transform: uppercase;
    }
}

.profile-button {
    text-decoration: none;
    width: auto;
    // margin: 5px;
    height: auto;
    color: $button-color;
    cursor: pointer;
    padding: 3px 6px;
    border: 1px solid $button-light2;
    background-color: $white-transparent;
    text-transform: uppercase;
    font-size: 0.8rem;
    transition: all 0.3s ease;

    &:hover {
        border: 1px solid $button-light2-hover;
        background-color: $white-transparent-darker;
    }
}

//identicaly with .profile-button - I want to change className from .profile-button to .button-light in future
.button-light {
    text-decoration: none;
    width: auto;
    // margin: 5px;
    height: auto;
    color: $button-color;
    cursor: pointer;
    padding: 0.2rem 0.4rem;
    border: 1px solid $button-light2;
    background-color: $white-transparent;
    font-size: 0.8rem;
    transition: all 0.3s ease;

    &:hover {
        border: 1px solid $button-light2-hover;
        background-color: $white-transparent-darker;
    }
}

.button-light-main {
    text-decoration: none;
    width: auto;
    // margin: 5px;
    height: auto;
    color: $main-color-dark;
    cursor: pointer;
    padding: 0.2rem 0.4rem;
    border: 1px solid $main-color;
    background-color: $white-transparent;
    font-size: 0.8rem;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    &:hover {
        border: 1px solid $main-color-dark;
        background-color: $white-transparent-darker;

    }
}

.update {
    padding: 0.2rem 0.4rem;
    background-color: $white-transparent;
    border: 1px solid $button-light;
    color: $button-color;
    cursor: pointer;
}

.delete {
    padding: 0.2rem 0.4rem;
    background-color: $white-transparent;
    border: 1px solid rgb(245, 190, 190);
    color: red;
    cursor: pointer;
}

// .formButton {
//     width: 100%;
//     height: 50px;
//     padding: 10px;
//     border: none;
//     background-color: rgb(144, 51, 153);
//     color: white;
//     border-radius: 5px;
//     font-weight: bold;
//     font-size: 18px;
//     cursor: pointer;
//     margin-top: 15px;
//     margin-bottom: 30px;
// }