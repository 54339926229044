@import '../../styles/variables';

.allcats {
    .catswrap {
        padding: 10px;
    }

    .block__row {
        display: flex;
        justify-content: space-around;
        /* align-items: stretch; */
        flex-wrap: wrap;
        border: 20px solid rgb(246, 246, 186);
        margin: 0px 0px 20px 0px;
    }

    .block__column {
        border: 20px solid rgb(241, 186, 241);
        flex: 1 0 200px;
    }

    .block__item {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        border: 10px solid hwb(180 66% 26%);
        padding: 10px;
    }

    .catimg {
        width: 200px;
        height: 150px;
        object-fit: cover;
        background-color: rgb(203, 236, 225);
    }

    h2 {
        margin: 2px;
    }

    .delete {
        border: none;
        padding: 3px 6px;
        background-color: white;
        cursor: pointer;
        border: 1px solid rgb(245, 190, 190);
        color: rgb(242, 100, 100);
    }

    .update {
        border: none;
        padding: 3px 6px;
        background-color: white;
        cursor: pointer;
        border: 1px solid rgb(204, 204, 243);
        color: rgb(139, 139, 234);
    }

    .but {
        /* text-decoration: none; */
        border-radius: 5px;
        padding: 3px 6px;
        cursor: pointer;
        border: 1px solid rgb(145, 97, 106);
    }
}