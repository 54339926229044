$main-color : rgb(195, 132, 144);
$main-color-dark: rgb(145, 97, 106);

.page-container {
    height: 120vh;
    background: linear-gradient(rgb(255, 255, 255, 0.6), rgb(118, 124, 118, 0.3)),
        url("../../../src/img/pink-background.jpeg");
    background-size: cover;
    // padding-top: 30px;

.add-photo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    min-width: 300px;
    border-radius: 10px;
    margin: 30px auto;
    padding: 40px;
    background-color: white;
    // box-shadow: 0 5px 18px gray;

    // Button variables
    $button-bg-color: #007bff;
    $button-text-color: #fff;
    $button-border-radius: 5px;
    $button-padding: 10px 20px;

    // Button styles
    button {
        background-color: $main-color;
        color: $button-text-color;
        border-radius: $button-border-radius;
        padding: $button-padding;
        border: 2px solid $main-color-dark;
        font-weight: 600;
        font-size: large;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            background-color: darken($button-bg-color, 10%);
        }
    }
}
}