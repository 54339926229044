// @import '../styles/variables';
// @import '../styles/base.scss';

@import '../../styles/global.scss';

.otp-input {

    display: flex;
    // justify-content: space-evenly;
    gap: 0.7rem;
    margin-top: 2rem;
    margin-bottom: 2rem;

    @include respond-to("medium") {
        // padding-top: 2rem;
        // padding-bottom: 2rem;
        justify-content: center;
        // justify-content: space-evenly;

    }

    .otp-input__input {
        // padding: 0.5rem;
        border-radius: 5px;
        border: 1px solid rgb(145, 97, 106);
        width: 3rem;
        height: 3rem;
        font-size: 1.5rem;
        text-align: center;
        // margin: 0 auto;

        @include respond-to("medium") {
            font-size: 1.3rem;
            // padding: 15px;
        }
    }
}

.email-varification {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(rgb(255, 255, 255, 0.6), rgb(118, 124, 118, 0.3)),
        url("../../img/pink-background.jpeg");
    background-size: cover;


    h1 {
        text-align: center;
    }

    .form-wrap {
        margin: 20px 30px;
        background-color: white;
        padding: 30px 30px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .joinform {
            display: flex;
            flex-direction: column;
            // margin: 10px 20px;
        }

        span {
            font-size: small;
            text-align: center;
        }
    }
}

.errorMessage {
    color: red;
    font-size: small;
    text-align: center;
}