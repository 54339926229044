@import '../../styles/variables';

.market {
    // padding: 7px;
}

.market__filter {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
}

.market__filter-breed {
    margin-bottom: 1.5rem;
    width: 50%;
    // font-style: ;
}

.market__cats-block {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 1fr;
    // grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    // padding: 0 7px 0 7px;
    // border: 2px solid lime;


    @include respond-to("medium") {
        grid-gap: 18px;
        grid-template-columns: 1fr 1fr;
    }

    @include respond-to("large") {
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @include respond-to("xl") {
        grid-gap: 24px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

}