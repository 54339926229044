@import '../../styles/global.scss';

.header-wrap {
    background-color: transparent;
    // padding: 0 8px 0 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // gap: 2rem;
    // border-bottom: 1px solid $lightGrayBack;
    


    .wraplogo {
        padding: 3px;
        // width: 80%;
    }

    .logo {
        width: auto;
        height: 60px;
    }

    .language-wrap {
        display: flex;
    }
}
