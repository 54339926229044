@import '../../styles/global.scss';


/////// DROPDOWN ////////
/// /* Style the dropdown button */
.dropbtn {
    background: $white-transparent;
    color: black;
    padding: 0 6px;
    font-size: x-large;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Dropdown container */
.dropdown {
    position: relative;
    display: inline-block;
    // right: 100px;
}

.dropbtn-header {
    background: $main-color;
    color: white;
    width: 40px;
    height: 40px;
    // padding: 5px 10px;
    font-size: x-large;
    border: 1px solid $main-color-dark;
    border-radius: 100%;
    cursor: pointer;
}

/* Dropdown content */
.dropdown-content {
    // display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 30px;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #f1f1f1
}

.drop__item {
    border-bottom: 1px solid $lightGrayBack;
}

.dropdown__small {
    font-style: italic;
    font-size: small;
    display: block;
}

// .cat-profile_others {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     align-content: stretch;
//     background-color: $lightGrayBack;
//     padding: 4px;
//     // border-top: 1px solid $lightGrayBack;
//     // border-bottom: 1px solid $lightGrayBack;

//     @include respond-to("xs") {
//         display: flex;
//         justify-content: space-between;
//         flex-direction: column;
//     }

.mate-clue {
    // border: 1px solid red;
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: all .09s ease-out;
    user-select: none;

    &::before {
        content: attr(data-clue);
        position: absolute;
        right: 5px;
        top: -40px;
        left: auto;
        /* Below the element */
        transform: translateY(0) translateX(0);
        padding: 10px 10px;
        color: whitesmoke;
        background-color: rgba($main-color, 0.9);
        border-radius: 3px;
        white-space: nowrap;
        visibility: hidden; // no in document
        opacity: 0;
        transition: opacity 0.3s ease-out, transform 0.3s ease-out;
        font-weight: normal;
        font-size: small;
        z-index: -1;

        @include respond-to("medium") {
            content: attr(data-clue);
            position: absolute;
            right: 0;
            /* Position on the left */
            top: -40px;
            left: auto;
            // transform: translateY(10px) translateX(0);
            padding: 10px 10px;
            color: whitesmoke;
            background-color: rgba($main-color, 0.9);
            border-radius: 3px;
            white-space: nowrap;
            visibility: hidden; // no in document
            opacity: 0;
            transition: opacity 0.3s ease-out, transform 0.3s ease-out;
            font-weight: normal;
            font-size: small;
            z-index: -1;
        }
    }

    &:hover {
        background-color: $main-color-back;

        &::before {
            visibility: visible;
            opacity: 1;
            z-index: 10;
            /* On mobile (default) */
            transform: translateY(-20%) translateX(0);
            /* Moves it further left smoothly */
            /* Moves further down smoothly */

            /* On medium and larger screens */
            @include respond-to("medium") {
                transform: translateY(-20%) translateX(0);
                /* Moves further left smoothly */
            }
        }
    }
}

// }