@import "../../styles/global.scss";


.cat-profile__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5px 0;
    // background-color: $main-color-back;

    @include respond-to("xs") {
        padding: 10px;
    }

}
