@import '../../styles/global.scss';

.male {
    background-color: $male-blue;
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

.male:hover {
    background-color: $male-blue-dark;
}

.female {
    background-color: $female-pink;
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

.female:hover {
    background-color: $female-pink-dark;
}

.cat-card-flex {
    display: flex;
    align-items: baseline;
    gap: 1.2rem;
}

.cat-card__registered {
    color: green;
    border: 2px dotted green;
    border-radius: 30%;
    padding: 2px 4px 2px 4px;
    font-size: small;
    text-align: center;
}

.cat-card__is-my-cat {
    color: grey;
    border: 2px dotted gray;
    border-radius: 30%;
    padding: 2px 4px 2px 4px;
    font-size: small;
    text-align: center;
}

.catblock__item {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    padding: 20px 10px;
    border: 1px solid lightgrey;
    border-radius: 20px;
    box-shadow: 0px 0px 20px lightgrey;
    text-decoration: none;
    color: inherit;

    // .catblock__item:hover {
    //     border: 1px solid black;
    // }

    .catimg {
        width: 200px;
        height: 150px;
        object-fit: cover;
        border: 1px solid lightgray;
        border-radius: 3px;
    }

    h2,
    h3 {
        margin: 2px;
        text-align: center;
    }

    // Here is another way to expand clickable area
    .large-link {
        text-align: center;
        // position: relative;

        // &::before {
        //     content: '';
        //     position: absolute;
        //     top: -80px; // increase this value to expand the clickable area above the link
        //     left: -50px; // increase this value to expand the clickable area to the left of the link
        //     right: -50px; // increase this value to expand the clickable area to the right of the link
        //     bottom: -30px; // increase this value to expand the clickable area below the link

        //     // Change the background color of the element with class .other-element on hover
        // }
    }

    // .large-link:hover+.catblock__item {
    //     background-color: blue;
    // }
}