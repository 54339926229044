@import "../../../styles/global.scss";



.cat-profile__header-block {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    background-color: $lightGrayBack;
    padding: 6px 2px;

    @include respond-to("xs") {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background-color: $lightGrayBack;
        padding: 8px 16px;
    }

    .cat-profile__header {
        margin: 0;
        padding: 4px 2px;
        text-align: left;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start
    }

    .cat-profile__registered {
        color: green;
        border: 2px dotted green;
        // border-radius: 15%;
        padding: 2px 4px 2px 4px;
    }

    .cat-profile__mycat {
        color: gray;
        border: 2px dotted gray;
        padding: 2px 4px 2px 4px;

        @include respond-to("xs") {
            color: gray;
            border: 2px dotted gray;
            padding: 2px 4px 2px 4px;
        }

        // button {
        //     width: 100%;
        //     height: 40px;
        //     bottom: 0;
        //     font-size: x-large;
        //     color: white;
        //     background-color: $main-color;
        //     border: none;
        //     margin: auto;
        //     cursor: pointer;
        // }
    }
}

.cat-card {
    display: flex;
    flex-direction: column;

    @include respond-to("xs") {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        border: 1px solid rgba(0, 0, 0, 0.2);
        width: 100%;
    }

    .cat-card__left {
        display: flex;
        flex-direction: column;
    }

    .cat-profile__right {

        @include respond-to("xs") {
            padding: 0 1rem 0;
            flex-grow: 1;
        }

        .cat-profile__right-title {
            // background-color: $lightGrayBack;
            text-align: center;
            font-weight: 600;
            font-size: large;
            margin: 5px 0 0 0;
        }

        .text {
            // margin: 0.3rem 0 0 0;
            line-height: 1.6rem;
        }

        .text-label {
            padding-right: 1rem;
            color: $main-color-dark;
            font-weight: 200;
            width: 95px;
            display: inline-block;
            font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

            @include respond-to("xs") {
                display: inline-block;
                // min-width: 95px;
                width: auto;
            }
        }

        .text-main {
            font-weight: 500;

            @include respond-to("xs") {
                display: inline-block;
                // width: 110px;
            }
        }

        // span {
        //     color: dimgray;

        //     @include respond-to("xs") {
        //         display: inline-block;
        //         width: 110px;
        //     }
        // }

    }

    .catAva {
        // max-width: 420px;
        // width: 140px;
        // height: 140px;
        // margin: 0 1rem 0 0;
        // flex: 0 1 50%;
        // border-radius: 5%;
        overflow: hidden; // This ensures the image doesn't spill out of the container
        // object-fit: cover;
        // position: relative;
        // display: inline-block;

        @include respond-to("xs") {
            max-width: 320px;
            // display: flex;
            // width: 100%;
            // height: auto;
            overflow: hidden; // This ensures the image doesn't spill out of the container
            // border-radius: 5%;
            // flex: 0 0 33%;
        }

        .catAva_relative {
            position: relative;

            @include respond-to("xs") {
                margin: 0 0 -5px 0;
                position: relative;

            }
        }

        .catAva__img {
            width: 100%;
            height: auto;
            object-fit: cover;

            @include respond-to("xs") {
                width: 100%;
                height: auto;
                object-fit: cover;

            }
        }

        .id-wrap {
            border: 1px solid white;
            background-color: $lightGrayBack;
            color: black;
            padding: 5px;
            position: absolute;
            /* position the icon absolute */
            top: 0;
            /* position the icon 0 from the top of the container */
            left: 0px;
            /* position the icon 0 from the right of the container */
            // transform: translate(-50%, -50%);
            /* add this line to center the icon */
        }

        .icon-mars-wrap {
            border: 1px solid white;
            background-color: $lightGrayBack;
            color: black;
            padding: 5px;
            position: absolute;
            /* position the icon absolute */
            top: 0;
            /* position the icon 0 from the top of the container */
            right: 0px;
            /* position the icon 0 from the right of the container */
            // transform: translate(-50%, -50%);
            /* add this line to center the icon */

            .icon-mars {
                color: rgb(36, 177, 233);
                font-size: 40px;
            }

            .icon-venus {
                color: rgb(245, 138, 222);
                font-size: 40px;
            }
        }
    }

    .breed-highlight {
        color: $main-color-dark;
        width: 100%;
        // background-color: $lightGrayBack;
        // display: inline-block;
        text-align: center;
        padding: 4px;
        font-style: italic;
        // font-size: small;
    }


    .card-id {
        text-align: right;
        font-size: x-small;
    }

}


.button-container {
    display: flex;
    justify-content: space-between;
}