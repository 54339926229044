@import '../styles/global.scss';


.cat-mini-card {
    &__card {
        display: grid;
        gap: 0.4rem;
        grid-template-columns: 0.4fr 2fr 2fr 2fr;
        // grid-template-rows: 2fr 2fr 1fr;
        padding: 8px 10px;
        margin-bottom: 15px;
        border-radius: 8px;
        border: 1px solid transparent;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: background-color 0.2s ease, box-shadow 0.2s ease;

        @include respond-to("medium") {
            display: grid;
            gap: 0.4rem;
            grid-template-columns: 0.5fr 1fr 2fr 2fr 1fr 1fr;

        }

        // Male cat base style
        &--male {
            background-color: $male-blue;
        }

        &--female {
            background-color: $female-pink;
        }

        &--male:hover {
            background-color: $male-blue-dark;
        }

        &--female:hover {
            background-color: $female-pink-dark;
            // border: 1px solid red;
        }

        &--selected {
            border: 1px solid rgb(145, 97, 106);
        }

        &:hover {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }
    }

    // .grid-col-span-2 {
    //     grid-column: span 2;
    // }

    // .grid-row-span-2 {
    //     grid-row: span 2;
    // }
    &__breed {
        color: $main-color-dark
    }

    &__ped {
        color: $main-color-dark;
        grid-column: span 2;
    }

    &__gray {
        color: gray
    }

    &__id {
        color: black;
        // flex: 0 0 40px;
        grid-row: span 2;
    }

    &__img {
        width: 80px;
        height: auto;
        grid-row: span 2;

    }

    // &__content {
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: start;
    //     gap: 1rem;
    //     flex: 1 3;
    // }

    &__footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
        margin-left: 60px;
        border: 1px solid blue;
    }

    &__item {
        margin-right: 2px;
        grid-column: span 2;
    }

    &__birthday {
        margin-right: 4px;
        // grid-column: span 2;
        font-size: small;
    }

    &__ems {
        background-color: rgba(255, 255, 255, 0.5);
        padding: 2px 6px;
        margin-right: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__colorems {
        background-color: $white-transparent;
        padding: 2px 6px;
        margin-right: 0;
        font-size: small;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__owned {
        font-style: italic;
        // background-color: $white-transparent;
        border: 1px dotted $main-color;
        padding: 1px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}