@import '../../styles/global.scss';

// //../../img/pink-background.jpeg

// .searchCat__container {
// //     display: flex;
// //     justify-content: center;
// //     // background: linear-gradient(rgb(255, 255, 255, 0.6), rgb(118, 124, 118, 0.3)),
// //     //     url("");
// //     background-size: cover;

//     @include respond-to("medium") {
// //         display: flex;
// //         justify-content: center;    
//         margin: 10px 0 10px 0;
// //         padding: 20px 20px;
// //         // background: linear-gradient(rgb(255, 255, 255, 0.6), rgb(118, 124, 118, 0.3)),
// //         //     url("");
// //         background-size: cover;
//         }

// }

.add-parent-search {
    margin: 10px 0 10px 0;
    padding: 10px 10px;
    // text-align: center;
    // border: 1px solid transparent;
// display: flex;
// justify-content:;
// align-items: ;
    @include respond-to("medium") {
        margin: auto;
        padding: 20px 20px;
    }

//     .breed_options {
//         margin: 10px 0 10px 0;
//     }

//     .searchCat__p {
//         margin: 0.8rem 0 1.2rem 0;
//     }

//     .searchCat__button {
//         display: flex;
//         justify-content: space-around;
//     }
// }

// .search {

//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     padding-top: 8px;
//     padding-bottom: 8px;

//     @include respond-to("medium") {
//         padding-top: 10px;
//         padding-bottom: 10px;

//     }

//     .form-input__label {
//         color: rgb(145, 97, 106);

//         @include respond-to("medium") {}
//     }

//     .form-input__error {
//         font-size: small;
//         padding: 3px;
//         color: red;
//         display: none;
//     }

//     input:invalid[focused="true"] {
//         border: 1px solid red;
//     }

//     input:invalid[focused="true"]~.form-input__error {
//         display: block;
//     }

//     .search__input {
//         padding: 12px;
//         border-radius: 5px;
//         border: 1px solid rgb(145, 97, 106);

//         @include respond-to("medium") {
//             font-size: 1.05rem;
//             padding: 15px;
//         }
//     }

//     .form-input__select {
//         padding: 3px;
//         border-radius: 5px;
//         border: 1px solid $main-color-dark;


//         div {
//             border: transparent;
//         }

//         @include respond-to("medium") {
//             padding: 8px;
//         }
//     }

//     .textarea {
//         resize: none;
//     }

//     .form-check-input {
//         border: 2px solid rgb(145, 97, 106);
//         border-radius: 5px;
//         width: 100px;
//         padding: 10px;
//         margin-bottom: 8px;
//         color: brown;

//         // @include respond-to("medium") {
//         //     // font-size: 1.2rem;
//         //     margin-bottom: 10px;

//         // }
//     }

//     .form-radio-button {
//         display: flex;
//         justify-content: space-around;
//         align-items: center;
//         // background-color: #ccc;
//         padding: 5px;
//         height: 50px;
//         border-radius: 5px;
//         border: 1px solid $main-color-dark;

//         .form-radio-button-item {
//             display: flex;
//             align-items: center;
//             padding: 5px;
//         }

//         &__input {
//             appearance: none;
//             position: relative;
//             width: 24px;
//             height: 24px;
//             margin-right: 10px;
//             cursor: pointer;

//             &:before {
//                 content: "";
//                 display: block;
//                 position: absolute;
//                 top: 50%;
//                 left: 50%;
//                 transform: translate(-50%, -50%);
//                 width: 16px;
//                 height: 16px;
//                 border-radius: 50%;
//                 background-color: #fff;
//                 box-shadow: inset 0 0 0 2px #ccc;
//             }

//             &:checked:before {
//                 background-color: #555;
//                 box-shadow: none;
//             }

//             &:hover:not(:checked):before {
//                 box-shadow: inset 0 0 0 2px #999;
//             }

//             &:after {
//                 content: "";
//                 display: block;
//                 position: absolute;
//                 top: 50%;
//                 left: 50%;
//                 transform: translate(-50%, -50%);
//                 width: 10px;
//                 height: 10px;
//                 border-radius: 50%;
//                 background-color: #fff;
//                 box-shadow: inset 0 0 0 2px #ccc;
//                 transition: all 0.3s ease;
//             }

//             &:checked:after {
//                 background-color: #555;
//                 box-shadow: none;
//                 transform: translate(-50%, -50%) scale(1);
//             }
//         }

//         &__label {
//             color: $main-color
//         }
//     }
}