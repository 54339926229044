@import '../../../styles/global.scss';

.cat-tab__dialog {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    // height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);

    .cat_tab__dialog_div {
        background-color: white;
        margin: 20px auto;
        padding: 20px;
        width: 80%;
    }

    .title-and-close {
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
    }

    .dialog__img {
        // width: 90%;
        // height: auto;
        margin: 0 auto;
        display: block; // Ensures centered block-level element

        // overflow: hidden;
        & img {
            // width: 100%;
            // height: auto;
            overflow: scroll;
            border: 1px solid red;
        }
    }
}