@import '../../../styles/global.scss';


.cattery {
    height: 340px;

    @include respond-to("medium") {
        height: 340px;
    }

    h2 {
        padding-bottom: 4px;
    }

    .strong {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .img-container {
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;

        @include respond-to("medium") {
            height: 340px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;

            // @include respond-to("medium") {
            //     width: 100%;
            //     height: 100%;
            //     object-fit: cover;
            //     display: block;
            // }
        }

        .text-overlay {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $white-transparent; // semi-transparent background
            // background-color: rgba(0, 0, 0, 0.5); // semi-transparent background
            // color: $main-color-dark; // text color
            // color: #fff; // text color
            padding: 10px;

        }
    }

    .cattery-no-breeder {
        // background-color: gray;
        // border: 1px solid $main-color;

        .profile-button {
            width: auto;
            // min-width: 80px;
            margin: 5px;
            height: auto;
            color: white;
            cursor: pointer;
            padding: 3px 6px;
            // background-color: $white-transparent;
            border: 1px solid rgb(204, 204, 243);
            color: rgb(139, 139, 234);
        }
    }

    .cattery-with-breeder {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        // align-items: center;


        .cattery-with-breeder__small {
            padding-top: 20px;

            // background-color: gray;
            // border: 1px solid $main-color;
            p {
                font-size: smaller;
                color: black;
            }
        }

        .cattery-with-breeder__text {
            padding-top: 4px;
        }
    }
}