@import '../../styles/variables';

.pagination-wrap {
    text-align: center;
}

.pagination {
    // display: flex;
    display: inline-flex;
    justify-content: center;
    // list-style: none;
    padding: 0;
    margin: 1.9rem 0 0 0;
}

.page-item {
    // margin: 0 5px;
    cursor: pointer;
    display: block;
    padding: 8px 12px;
    text-decoration: none;
    color: #333;
    background-color: #fff;
    border: 1px solid #ddd;
    font-weight: 800;

    &.active {
        color: #fff;
        background-color: $main-color;
        cursor: default
    }

    &:hover:not(.active) {
        background-color: $main-color-back;
    }

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &+.page-item {
        border-left: none;
    }

    &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .page-link {
        display: block;
        text-decoration: none;
    }
}