@import "../../../styles/global.scss";


.cat-profile__parents {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4rem;
}

.cat-profile__parents-item {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.cat-profile__buttons{
    display: flex;
    gap: 2rem;
    margin: 0.7rem 0 0 0;
}

.cat-profile__parents-header {
    margin: 0.4rem 0 0.9rem 0;
}