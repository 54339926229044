@import '../../../styles/variables';

.clue {
    position: relative;
    display: inline-block;
    background-color: $lightGrayBack;
    color: $main-color-dark;
    padding: 0.01rem 0.50rem;
    margin: 0 14px;
    border-radius: 50%;
    cursor: pointer;
    transition: all .09s ease-out;
    user-select: none;
    font-size: small;

    &:hover {
        font-weight: 600;
        background-color: rgba(darkorchid, 0.3);
    }

    &:before {
        content: attr(data-clue);
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding: 10px 10px;
        color: whitesmoke;
        background-color: rgba(darkorchid, 0.9);
        min-width: 180px;
        border-radius: 3px;
        visibility: hidden; // no in document
        z-index: -1;
        opacity: 0;
        transition: inherit;
        font-weight: normal;
    }

    &:hover {
        background-color: rgba(darkorchid, 0.3);

        &::before {
            opacity: 1;
            visibility: visible;
            z-index: 10;
            bottom: calc(100% + 10px);
        }
    }
}