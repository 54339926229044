@import "../../../../styles/global.scss";


// .selling-tab__h3 {
//     margin: 0;
//     padding: 0;
// }

// .selling-tab__form{
//     margin: 1rem 0;
//     padding: 1rem;
//     border: 1px solid $main_color;
// }

.selling-tab {
    padding: 12px;

    .selling-tab__heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 2px 0 10px 0;
        padding: 2px
    }

    h3 {
        margin: 0;
        padding: 0
    }

    .selling-tab__active {
        margin-right: 0.9rem;
        color: #000; // main text color
        text-shadow: 0 0 5px $main_color_back, // Синий оттенок свечения
            0 0 10px $main_color,
            // 0 0 15px blue,
            0 0 20px red, // Переход к красному
            0 0 30px red,
            0 0 40px red;
    }

    // .selling-tab__active {
    //     margin-right: 0.8rem;
    //     background: linear-gradient(to right, blue, red);
    //     -webkit-background-clip: text;
    //     -webkit-text-fill-color: transparent;
    //     background-clip: text;
    // }

    .selling-tab__not_active {
        margin-right: 0.9rem;
        // color: $main_color;
    }

    .cancel-button {
        margin: 14px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 15px;
        background-color: #f9f9f9;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

        h4 {
            font-size: 1.25rem;
            color: #333;
            margin-bottom: 10px;
        }

        label {
            display: block;
            font-size: 0.9rem;
            margin-bottom: 5px;
            color: #555;
        }

        input[type="text"],
        input[type="date"],
        textarea {
            width: 100%;
            padding: 10px;
            font-size: 1rem;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-sizing: border-box;

            &:focus {
                border-color: #007bff;
                outline: none;
                box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
            }
        }

        input[type="checkbox"] {
            margin-right: 10px;
        }

        .selling__textarea {
            textarea {
                resize: vertical;
            }
        }

        .sent {
            margin-right: 1rem;
        }

        // button {
        //     align-self: flex-start;
        //     padding: 10px 20px;
        //     font-size: 1rem;
        //     color: #fff;
        //     background-color: #28a745;
        //     border: none;
        //     border-radius: 4px;
        //     cursor: pointer;

        //     &:hover {
        //         background-color: #218838;
        //     }
        // }

        div {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            label {
                margin-right: 10px;
            }

            &>div {
                flex: 1;
            }
        }

        .price-container {
            display: flex;
            align-items: center;

            input[type="text"] {
                margin-right: 10px;
            }
        }
    }

    .selling-info {
        // Container styles
        padding: 12px;
        background-color: #f9f9f9;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin: 1.2rem 0 1.2rem 0;

        .selling__text {
            display: flex;
            flex-direction: row;
            gap: 1rem;
        }

        .selling__text_label {
            // Styles for the pet price
            // font-weight: bold;
            color: #502c3c;
        }
    }
}

.toggle-switch {
    position: relative;
    width: 60px;
    height: 30px;

    &__checkbox {
        display: none;

        &:checked+.toggle-switch__label::before {
            transform: translateX(30px);
            background-color: $main_color; // Active color
        }

        &:checked+.toggle-switch__label {
            background-color: #ccc; // Background color when active
        }
    }

    &__label {
        display: block;
        width: 100%;
        height: 100%;
        background-color: #ddd; // Inactive background color
        border-radius: 15px;
        cursor: pointer;
        transition: background-color 0.3s;

        &::before {
            content: '';
            position: absolute;
            top: 3px;
            left: 3px;
            width: 24px;
            height: 24px;
            background-color: #fff;
            border-radius: 50%;
            transition: transform 0.3s, background-color 0.3s;
        }
    }



}