@import '../../styles/global.scss';

/////// CATTERY ////////

.cattery {
    height: auto;
    display: flex;
    justify-content: center;
    background: linear-gradient(rgb(255, 255, 255, 0.6), rgb(118, 124, 118, 0.3)),
        url("../../img/pink-background.jpeg");
    background-size: cover;


    @include respond-to("medium") {
        display: flex;
        justify-content: center;
        // height: 100vh;
        // border: 1px solid red;
        background: linear-gradient(rgb(255, 255, 255, 0.6), rgb(118, 124, 118, 0.3)),
            url("../../img/pink-background.jpeg");
        background-size: cover;
    }

    .messageError {
        color: red;
        margin: 2px;
    }

    .cattery-form {
        background-color: white;
        width: 320px;
        border-radius: 10px;
        padding: 10px;
        // font-size: small;
        margin: 20px;
        padding: 20px 30px;

        @include respond-to("medium") {
            background-color: white;
            border-radius: 10px;
            width: 420px;
            margin: 60px;
            padding: 60px;
        }

        .cattery-selectInput {
            padding-top: 5px;

            .selectInput__label {
                color: $main-color-dark;

                @include respond-to("medium") {
                    color: $main-color-dark;
                }
            }

            .selectInput__input {
                padding: 3px;
                border-radius: 5px;
                border: 1px solid $main-color-dark;

                @include respond-to("medium") {
                    padding: 8px;
                }

                div {
                    border: transparent;
                }
            }
        }
    }
}