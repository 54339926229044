@import '../../styles/global.scss';

.add-cat-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background: linear-gradient(rgb(255, 255, 255, 0.6), rgb(118, 124, 118, 0.3)),
    //     url("../../img/pink-background.jpeg");
    // background-size: cover;
}

.add-cat-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    min-width: 300px;
    border-radius: 10px;
    margin: 10px auto 60px auto;
    padding: 40px;
    background-color: white;

}
