@import './_variables';

* {
    box-sizing: border-box;
    // margin: 0 auto; // do not let make flex space-between.
    padding: 0;
}

.app {
    // height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    // font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    //   monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .container {
        padding: 5px;
        margin: 0 auto;
        width: 100%;
        max-width: 1600px;

        @include respond-to("large") {
            max-width: 1600px;
            padding: 0 20px;
            // margin: 0 auto;
            // place-items: center; // shorthand for align-items and justify-items
        }

        @include respond-to("xl") {
            max-width: 1600px;
            padding: 0 20px;
        }
    }

    .header__link {
        text-decoration: none;
        color: inherit;
        font-size: large;
        font-weight: 300;
        border-bottom: 1px solid $main-color;
    }

    .nolink {
        text-decoration: none;
        color: inherit;
    }

    .link {
        color: $main-color-dark;
        text-decoration: none;
        padding-bottom: 2px;
        border-bottom: 1px dotted $main-color-dark;
        // background-color: transparent;

        &:hover {
            color: black;
            text-decoration: none;
            border-bottom: 1px dotted black;
            // background-color: gray;
        }

        // :visited overwrites hover
        // &:visited {
        //     color: $main-color-dark;
        //     text-decoration: none;
        //     border-bottom: 1px dotted $main-color-dark;
        // }

        &:active {
            color: $main-color-dark;
            text-decoration: none;
            border-bottom: 1px dotted $main-color-dark;
        }
    }

    .link-button {
        border: none;
        color: $main-color-dark;
        width: auto;
        box-sizing: border-box;
        // text-decoration: underline;
        background-color: transparent;
        cursor: pointer;
        padding-bottom: 2px;
        border-bottom: 1px solid $main-color-dark;

        &:hover {
            color: black;
            text-decoration: none;
            border-bottom: 1px solid black;
            // background-color: gray;
        }
    }

}

/////// FORM INPUT //////
// .form-input {

//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     padding-top: 8px;
//     padding-bottom: 8px;

//     @include respond-to("medium") {
//         padding-top: 10px;
//         padding-bottom: 10px;

//     }

//     .form-input__label {
//         color: rgb(145, 97, 106);

//         @include respond-to("medium") {}
//     }

//     .form-input__error {
//         font-size: small;
//         padding: 3px;
//         color: red;
//         display: none;
//     }

//     input:invalid[focused="true"] {
//         border: 1px solid red;
//     }

//     input:invalid[focused="true"]~.form-input__error {
//         display: block;
//     }

//     .form-input__input {
//         padding: 12px;
//         border-radius: 5px;
//         border: 1px solid rgb(145, 97, 106);

//         @include respond-to("medium") {
//             font-size: 1.05rem;
//             padding: 15px;
//         }
//     }

//     .form-input__select {
//         padding: 3px;
//         border-radius: 5px;
//         border: 1px solid $main-color-dark;


//         div {
//             border: transparent;
//         }

//         @include respond-to("medium") {
//             padding: 8px;
//         }
//     }

//     .textarea {
//         resize: none;
//     }

//     .form-check-input {
//         border: 2px solid rgb(145, 97, 106);
//         border-radius: 5px;
//         width: 100px;
//         padding: 10px;
//         margin-bottom: 8px;
//         color: brown;

//         // @include respond-to("medium") {
//         //     // font-size: 1.2rem;
//         //     margin-bottom: 10px;

//         // }
//     }

// .form-radio-button {
//     display: flex;
//     justify-content: space-around;
//     align-items: center;
//     // background-color: #ccc;
//     padding: 5px;
//     height: 50px;
//     border-radius: 5px;
//     border: 1px solid $main-color-dark;

// .form-radio-button-item {
//     display: flex;
//     align-items: center;
//     padding: 5px;
// }

// &__input {
//     appearance: none;
//     position: relative;
//     width: 24px;
//     height: 24px;
//     margin-right: 10px;
//     cursor: pointer;

//     &:before {
//         content: "";
//         display: block;
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//         width: 16px;
//         height: 16px;
//         border-radius: 50%;
//         background-color: #fff;
//         box-shadow: inset 0 0 0 2px #ccc;
//     }

//     &:checked:before {
//         background-color: #555;
//         box-shadow: none;
//     }

//     &:hover:not(:checked):before {
//         box-shadow: inset 0 0 0 2px #999;
//     }

//     &:after {
//         content: "";
//         display: block;
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//         width: 10px;
//         height: 10px;
//         border-radius: 50%;
//         background-color: #fff;
//         box-shadow: inset 0 0 0 2px #ccc;
//         transition: all 0.3s ease;
//     }

//     &:checked:after {
//         background-color: #555;
//         box-shadow: none;
//         transform: translate(-50%, -50%) scale(1);
//     }
// }

// &__label {
//     color: $main-color
// }
// }
// }