@import '../../styles/global.scss';

// FOOTER
footer {

    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    padding: 20px 0 0 0;
    background-color: transparent;
    width: 100%;

    .footer__link {
        text-align: center;
        flex: 1 1 calc(33.333% - 10px);
        /* 3 items per row, accounting for gap */
    }

    .footer__img {
        // text-align: center;
        flex: 1 1 calc(33.333% - 10px);
        /* 3 items per row, accounting for gap */
        align-self: flex-start;

        img {
            height: 60px;
        }
    }

}