@import '../../styles/variables';

.sell-block {
    // margin-inline: auto;
}

.sell-block__wrap {
    padding: 7px;
    display: grid;
    gap: 0.7rem;
    grid-template-columns: 1fr 1fr 1fr;

    // s = 576, medium = 768 large = 1024 xl = 1200
    @include respond-to("medium") {
        gap: 1.5rem;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @include respond-to("large") {
        gap: 2rem;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    @include respond-to("xl") {
        gap: 2rem;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

.sell-block__button_wrap {
    // padding: 10px;
    margin-top: 1.5em;
}