@import '../../../styles/global.scss';

.TabView {
    width: 100%;

    .title {
        // padding: 0.5em;
        font-weight: 600;
    }

    .body {
        padding: 1em 0;
    }

    .tabs {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


    .tab {
        width: 100%;
        padding: 0.3rem;
        box-sizing: border-box;
        border-left: 1px solid $lightGrayBack;
        border-top: 1px solid $lightGrayBack;
        border-right: 1px solid $lightGrayBack;
        color: $main-color;
        text-align: center;
        font-size: small;

        &:hover {
            cursor: pointer;
        }

        @include respond-to("medium") {
            padding: 5px 1em;
            box-sizing: border-box;
        }
    }

    .active-tab {
        width: 100%;
        padding: 0.3rem;
        border-left: 1px solid $lightGrayBack;
        border-top: 1px solid $lightGrayBack;
        border-right: 1px solid $lightGrayBack;
        background-color: $lightGrayBack;
        color: $main-color-dark;
        font-weight: 600;
        text-align: center;
        font-size: small;
        @include respond-to("medium") {
            padding: 5px 1em;

        }
    }

    .tab-content {
        // display: flex;
        // flex-direction: column;
        // align-content: center;
        padding: 1em;
        background-color: $lightGrayBack;
        // border-top: 1px solid $main-color;
    }
}