@import '../../styles/variables';

.cats-container {
    padding: 10px;

    @include respond-to("medium") {
        padding: 30px;
    }
}

.catblock__row {
    display: flex;
    justify-content: space-around;
    /* align-items: stretch; */
    flex-wrap: wrap;
    gap: 40px;
    // border: 20px solid rgb(246, 246, 186);
}

.cats__upperBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 1rem 1rem 1rem;
    gap: 3px;

    @include respond-to("medium") {
        justify-content: center;
        font-size: medium;
        flex-direction: row;
    }
}