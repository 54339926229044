// @import '../styles/variables';
// @import '../styles/base.scss';

@import '../../styles/global.scss';


//LOGIN

.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(rgb(255, 255, 255, 0.6), rgb(118, 124, 118, 0.3)),
        url("../../../src/img/pink-background.jpeg");
    background-size: cover;


    h1 {
        text-align: center;
    }

    .joinform {
        display: flex;
        flex-direction: column;
        background-color: white;
        padding: 30px 50px;
        border-radius: 10px;
        margin: 10px 10px;
    }

    span {
        font-size: small;
        text-align: center;
    }
}

.errorMessage {
    color: red;
    font-size: small;
    text-align: center;
}