@import '../../styles/global';

.form-input {
    border: none;  /* Removes the default border */
    padding: 0;    /* Resets padding */
    margin: 0;     /* Resets margin */
    padding-top: 8px;

    @include respond-to("medium") {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .form-input__label {
        color: rgb(145, 97, 106);

        @include respond-to("medium") {
            // align-self: flex-start;  
        }
    }

    .form-input__error {
        font-size: small;
        padding: 3px;
        color: red;
        // display: none;
    }

    input:invalid[focused="true"] {
        border: 1px solid red;
    }

    input:invalid[focused="true"]~.form-input__error {
        display: block;
    }

    .form-input__input {
        padding: 12px;
        border-radius: 5px;
        border: 1px solid rgb(145, 97, 106);
        width: 100%;

        @include respond-to("medium") {
            font-size: 1.05rem;
            padding: 15px;
        }
    }

    .form-input__select {
        padding: 3px;
        border-radius: 5px;
        border: 1px solid $main-color-dark;


        div {
            border: transparent;
        }

        @include respond-to("medium") {
            padding: 8px;
        }
    }

    .textarea {
        resize: none;
    }

    .form-check-input {
        border: 2px solid rgb(145, 97, 106);
        border-radius: 5px;
        width: 100px;
        padding: 10px;
        margin-bottom: 8px;
        color: brown;

        // @include respond-to("medium") {
        //     // font-size: 1.2rem;
        //     margin-bottom: 10px;

        // }
    }

    .form-radio-button {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 5px;
        height: 50px;
        border-radius: 5px;
        border: 1px solid $main-color-dark;

        .form-radio-button__item {
            padding: 5px;
        }

        .form-radio-button__label {
            display: flex;
            flex-direction: row;
            background-color: rgb(234, 239, 239);
            padding: 4px 8px 4px 8px;
            border-radius: 3px;

            &:hover {
                background-color: rgb(211, 210, 210);
                border-radius: 3px;
            }

            p {
                padding: 5px;
            }

        }

        // &__input {
        //     appearance: none;
        //     position: relative;
        //     width: 26px;
        //     height: 26px;
        //     margin-right: 10px;
        //     cursor: pointer;

        //     &:before {
        //         content: "";
        //         display: block;
        //         position: absolute;
        //         top: 50%;
        //         left: 50%;
        //         transform: translate(-50%, -50%);
        //         width: 18px;
        //         height: 18px;
        //         border-radius: 50%;
        //         background-color: #fff;
        //         box-shadow: inset 0 0 0 2px #ccc;
        //     }

        //     &:checked:before {
        //         background-color: #555;
        //         box-shadow: none;
        //     }

        //     &:hover:not(:checked):before {
        //         box-shadow: inset 0 0 0 2px #999;
        //     }

        //     &:after {
        //         content: "";
        //         display: block;
        //         position: absolute;
        //         top: 50%;
        //         left: 50%;
        //         transform: translate(-50%, -50%);
        //         width: 10px;
        //         height: 10px;
        //         border-radius: 50%;
        //         background-color: #fff;
        //         box-shadow: inset 0 0 0 2px #ccc;
        //         transition: all 0.3s ease;
        //     }

        //     &:checked:after {
        //         background-color: #555;
        //         box-shadow: none;
        //         transform: translate(-50%, -50%) scale(1);
        //     }
        // }

        // &__label {
        //     // color: $main-color;
        //     border: 1px solid red;
        //     display: flex;
        //     flex-direction: row;
        //     align-items: center;
        //     justify-content: center;
        //     padding: 5px;

        // }
    }
}