@import '../../styles/variables';

.buy-order__item {
    margin: 1.4rem 0 1.4rem 0;
    padding: 1rem 0.5rem 1rem 0.5rem;
    border: 1px solid $main_color;
}

.buy-order__pet {
    margin: 1rem 0 1rem 0;
}