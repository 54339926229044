.upload-photo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.5rem 0 1.5rem 0;
    // background-color: lightpink;

    .upload-label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        font-weight: bold;
        color: #777;
        border: 2px dashed #ddd;
        border-radius: 8px;
        padding: 1rem;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            background-color: #f2f2f2;
        }
    }

    small {
        line-height: 2;
        margin-bottom: 5px;
    }

    .photo-input {
        display: none;
    }

    .preview-container {
        display: flex;
        justify-content: center;
        // align-items: center;
        margin-top: 1.3rem;
        width: 100%;
        // height: 100%;
        max-height: 800px;
        // border: 3px solid pink;
        // border-radius: 5px;
        // padding: 5px 5px 5px 5px;

        img {
            width: 100%;
            // max-width: 320px;
            // height: 100%;
            // object-fit: cover;
        }
    }
}