// _variables.scss

// recommended naming convention is to use hyphen-separated (kebab-case) names. 
// This style is both readable and widely used in the CSS community.

$main-color : rgb(195, 132, 144);
$main-color-back : rgb(195, 132, 144, 0.2);
$main-color-back2 : rgb(195, 132, 144, 0.3);
$main-color-dark: rgb(145, 97, 106);
$white-transparent: rgba(255, 255, 255, 0.5);
$lightGrayBack: rgb(245, 245, 245);
// $lightBrawn: rgb(239, 235, 233);
// rgba(233, 240, 249, 255)
// rgb(210, 216, 224)
// rgba(225, 212, 224, 1)
$female-pink: rgba(250, 236, 249, 255);
$female-pink-dark: rgba(240, 226, 239, 255);
$male-blue: rgba(233, 240, 249, 255);
$male-blue-dark: rgba(223, 230, 239, 255);
$icon-mars: rgba(36, 177, 233, 1);
$icon-venus:rgba(245, 138, 222, 1);

$button-color: rgb(139, 139, 234);
$button-light: rgb(204, 204, 243);
$button-light-hover: rgb(204, 204, 243, 0.5);
$button-light2: rgb(170, 160, 255,0.5);
$button-light2-hover: rgb(170, 160, 255);
$white-transparent-darker: rgba(255, 255, 255, 0.8);

// Button variables
$button-bg-color: #007bff;
$button-text-color: #fff;
$button-border-radius: 8px;
$button-padding: 0.25rem 1rem;

// Works in SCSS:
$breakpoints: (
    "xs": 414px,
    "s": 576px,
    //min width
    "medium": 768px,
    "large": 1024px,
    "xl": 1200px,
);

// Define a mixin to apply styles based on a given breakpoint
@mixin respond-to($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }
}

// Converting SCSS variables to CSS custom properties to use in components which use inline js styles
:root {
    --main-color: #{$main-color};
    --main-color-back: #{$main-color-back};
    --female-pink: #{$female-pink};
    --female-pink-dark: #{$female-pink-dark};
    --male-blue: #{$male-blue};
    --male-blue-dark: #{$male-blue-dark};
    --icon-mars: #{$icon-mars};
    --icon-venus: #{$icon-venus};
    // --hover-color: #{$main-color-back2};
    // --selected-color: #{$SelectedColor};
    // --focused-background-color: #{$FocusedBackgroundColor};
    // --selected-background-color: #{$SelectedBackgroundColor};
    // --control-border-radius: #{$ControlBorderRadius}px;
    // --control-padding: #{$ControlPadding}px;
}