@import '../../../styles/global.scss';

.cat-owner-tab {
    // border: 2px solid red;
    // display: flex;
    // flex-wrap: wrap;
    // height: 340px;

    @include respond-to("medium") {
        // display: flex;
        // flex-wrap: wrap;
        // height: 340px;
    }

    .profile__up {
        display: flex;
        margin: 0.4rem 0 0.4rem 0;
        font-size: small;
    }

    .profile__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .img-container {
        width: 140px;
        height: 140px;
        margin: 0 1rem 0 0;
        // flex: 0 1 50%;
        border-radius: 5%;
        overflow: hidden; // This ensures the image doesn't spill out of the container
        // object-fit: cover;
        // position: relative;
        // display: inline-block;

        @include respond-to("medium") {
            width: 200px;
            height: 200px;
            overflow: hidden; // This ensures the image doesn't spill out of the container
            border-radius: 5%;
        }

        img {
            width: 140px;
            height: 140px;
            object-fit: cover;
            display: block;

            @include respond-to("medium") {
                width: 200px;
                height: 200px;
                object-fit: cover;
                // display: block;
            }
        }

        // .text-overlay {
        //     position: absolute;
        //     bottom: 0;
        //     left: 0;
        //     width: 100%;
        //     background-color: rgba(0, 0, 0, 0.5); // semi-transparent background
        //     color: #fff; // text color
        //     padding: 10px;

        // }
    }

    .profile__info {
        // background-color: $white-transparent;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        @include respond-to("medium") {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        }

        div {
            // padding: 20px;
            width: 100%;
            height: auto;

        }

        span {
            // display: inline-block;
            // width: 100px;

            @include respond-to("medium") {
                // display: inline-block;
                // width: 110px;
            }
        }

        p {
            // padding-top: 10px;
        }

    }

    .button-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;

    }
}

// /////// DROPDOWN ////////
// /// /* Style the dropdown button */
// .dropbtn {
//     background: $white-transparent;
//     color: black;
//     padding: 0 6px;
//     font-size: x-large;
//     border: none;
//     border-radius: 5px;
//     cursor: pointer;
// }

// /* Dropdown container */
// .dropdown {
//     position: relative;
//     display: inline-block;
//     // right: 100px;
// }

// .dropbtn-header {
//     background: $lightGrayBack;
//     color: black;
//     padding: 0 6px;
//     font-size: x-large;
//     border: 1px solid gray;
//     border-radius: 5px;
//     cursor: pointer;
// }
// /* Dropdown content */
// .dropdown-content {
//     // display: none;
//     position: absolute;
//     background-color: #f9f9f9;
//     min-width: 160px;
//     box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
//     z-index: 1;
//     right: 30px;
// }

// /* Links inside the dropdown */
// .dropdown-content a {
//     color: black;
//     padding: 12px 16px;
//     text-decoration: none;
//     display: block;
// }

// /* Change color of dropdown links on hover */
// .dropdown-content a:hover {
//     background-color: #f1f1f1
// }