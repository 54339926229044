// body {
//     font-family: Arial, sans-serif;
//     line-height: 1.6;
// }

.privacy-policy {
    padding: 2rem;
    width: 90%;
    margin: 0 auto;

    h1 {
        padding: 1.5rem 0 0.5rem 0;
        color: #333;
    }

    h2 {
        // padding: 1.5rem 0 0.5rem 0;
        color: #333;
    }

    p {
        padding: 0.2rem 0 0.4rem 2rem;
    }

    ul {
        padding: 0.2rem 0 0.4rem 2rem;

        li {
            padding: 0.2rem 0 0.4rem 0.8rem;
        }
    }
}