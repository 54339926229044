@import '../../styles/global.scss';

// $male-blue: rgba(233, 240, 249, 255);
// $female-pink: rgba(250, 236, 249, 255);
// $white-transparent: rgba(255, 255, 255, 0.5);
// $main-color : rgb(195, 132, 144);
// $main-color-dark: rgb(145, 97, 106);


.cat-edit-container {
    // height: 100vh;
    // background: linear-gradient(rgb(255, 255, 255, 0.6), rgb(118, 124, 118, 0.3)),
    //     url("../../img/pink-background.jpeg");
    // background-size: cover;
    // padding-top: 50px;
    // padding-bottom: 50px;

    @include respond-to("medium") {
        background: linear-gradient(rgb(255, 255, 255, 0.6), rgb(118, 124, 118, 0.3)),
            url("../../img/pink-background.jpeg");
        background-size: cover;
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.cat-edit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    min-width: 300px;
    border-radius: 10px;
    margin: 0 auto;
    padding: 5px;
    background-color: white;

    @include respond-to("medium") {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 600px;
        min-width: 300px;
        border-radius: 10px;
        margin: 0 auto;
        padding: 40px;
        background-color: white;
    }

    .edit-cat-profile__text {
        color: #555;
        padding-right: 5px;
    }

    .edit-cat-profile__color {
        display: flex;

    }

    form {
        background-color: white;
        padding: 20px 30px;
        border-radius: 10px;
        min-width: 300px;

        .cat-edit__form-input {
            margin-top: 30px;
            height: 60px;
            width: 100%;

            input {
                padding: 10px;
                width: 100%;
                height: 50px;
                border: 1px solid lightgrey;
                border-radius: 5px;
            }

        }

        .cat-edit__textarea {
            margin-top: 30px;
            width: 100%;
            font-family: Arial, sans-serif;
            font-size: 16px;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            background-color: #fff;
            color: #333;
            resize: none;
        }

        .form-input-select {
            margin-top: 30px;

            input {
                height: 40px;
            }
        }

        .radio-button {
            display: flex;
            justify-content: space-around;
            align-items: center;
            background-color: #ccc;
            padding: 5px;
            height: 50px;
            margin-top: 30px;
            border-radius: 5px;

            div {
                display: flex;
                align-items: center;
                padding: 10px;
            }

            &__input {
                appearance: none;
                position: relative;
                width: 24px;
                height: 24px;
                margin-right: 10px;
                cursor: pointer;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: #fff;
                    box-shadow: inset 0 0 0 2px #ccc;
                }

                &:checked:before {
                    background-color: #555;
                    box-shadow: none;
                }

                &:hover:not(:checked):before {
                    box-shadow: inset 0 0 0 2px #999;
                }

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: #fff;
                    box-shadow: inset 0 0 0 2px #ccc;
                    transition: all 0.3s ease;
                }

                &:checked:after {
                    background-color: #555;
                    box-shadow: none;
                    transform: translate(-50%, -50%) scale(1);
                }
            }

            &__label {
                font-size: 16px;
                padding: 10px;
            }
        }

    }

    .cat-color {
        margin: 1rem 0 0.5rem 0;
        padding: 1rem 0.7rem 1rem 0.7rem;
        border: dashed 2px $main-color;
        border-radius: 5px;
    }
}