.admin__dialog {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);

    .admin__dialog_div {
        background-color: white;
        margin: 20px auto;
        padding: 20px;
        width: 100%;
    }

    .title-and-close {
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
    }

    .dialogAdmin__img {
        width: 100%;
        // display: flex;
        // justify-content: center;
        // border: 2px solid red;
        margin-bottom: 10px;

        img {
            width: 100%;
            margin: 0 auto;
        }
    }

}