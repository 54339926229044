@import '../../styles/variables';


.list-group {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 0 0 1rem;
    font-size: small;

    @include respond-to("medium") {
        justify-content: space-between;
        font-size: medium;
    }

    .list-group-item {
        background-color: #f8f9fa;
        padding: 10px;
        margin-bottom: 5px;
        border: 1px solid #ddd;
        cursor: pointer;

        &.active {
            color: #fff;
            background-color: $main-color;
            cursor: default;
            border: 1px solid $main-color;
        }

        &:hover:not(.active) {
            background-color: lightgrey;
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .list-group-item:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .list-group-item:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .list-group-item+.list-group-item {
        border-left: none;
    }
}