@import '../../../styles/global.scss';

.cat-tab {
    padding: 0.5rem;

    // details {
    //     width: auto;
    //     padding: 0.5rem;
    // }

    .metric {
        padding: 0 0 10px 0;

        h3 {
            padding: 0 0 8px 0;
            margin: 0;
        }

        .metric-block {
            display: flex;
            // justify-content: flex-start;
            flex-direction: row;
            gap: 10px;

            @include respond-to("xs") {
                display: flex;
                // min-width: 95px;
                gap: 20px;
            }
        }


        // .metric-img {
        //     width: 80px;
        //     height: auto;
        //     float: left;
        //     padding-right: 8px
        // }

        .metric-text {
            display: flex;
            flex-direction: column;

            // align-items: center;
            // flex-wrap: wrap;
            // height: 60px;
            // padding-left: 0.5rem;
            @include respond-to("xs") {
                display: flex;
                // min-width: 95px;
                width: auto;
            }
        }
    }

    .metric-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
    }

    .clue-text {
        font-weight: 200;
        font-size: small;
        font-style: italic;
        margin-top: 5px;
    }

    .text-label {
        padding-right: 1rem;
        color: $main-color-dark;
        font-weight: 200;
        width: 95px;
        display: inline-block;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

        @include respond-to("xs") {
            display: inline-block;
            min-width: 160px;
            width: auto;
            line-height: 1.6rem;
        }
    }

    .text-main {
        font-weight: 500;

        @include respond-to("xs") {
            display: inline-block;
            // width: 110px;
        }
    }

    .clear {
        clear: both;
    }
}

.registered {
    margin: 0 0 0 1rem;
    color: $main-color;
}

.margin-button {
    margin: 0 2rem 0 2rem;
}

.dialog__img {
    width: 100%;
    // display: flex;
    // justify-content: center;
    // border: 2px solid red;
    margin-bottom: 10px;

    img {
        width: 100%;
        margin: 0 auto;
    }
}

.big {
    font-weight: 600;
    font-size: larger;
}

// .inline-block {
//     display: inline-block;
//     margin-right: 1rem;
// }

// .gray {
//     color: gray;
// }

.cat-tab__dialog {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);

    .cat_tab__dialog_div {
        background-color: white;
        margin: 20px auto;
        padding: 20px;
        width: 80%;
    }

    .title-and-close {
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
    }

}

button[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
}